<template>
  <v-container />
</template>

<script>
import { mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import StoreChannelType from 'enum/storeChannelType'
export default {
  data: () => ({
  }),
  created () {
    if (this.storeChannelsIdInRouteParams() !== null &&
      parseInt(this.storeChannelsIdInRouteParams()) === StoreChannelType.LIEN_VIET_POST_BANK) {
      let dataParams = this.$route.query
      let sessionFilter = {
        params: {
          key: dataParams.key,
          data: dataParams.data
        }
      }
      this.GET_VI_VET_SESSION(sessionFilter).then(
        function (res) {
          sessionStorage.setItem('viVietSession', JSON.stringify(res.data))
          this.$router.push({
            name: 'MobilePortalProductList',
            params: {
              'storeChannelId': this.storeChannelsIdInRouteParams()
            }
          })
        }.bind(this)
      ).catch(
        function () {
          this.$router.push({
            path: '/lpb-info-invalid'
          })
        }.bind(this)
      )
    } else {
      this.$router.push({
        name: 'MobilePortalProductList',
        params: {
          'storeChannelId': this.storeChannelsIdInRouteParams()
        }
      })
    }
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    ...mapActions([
      'GET_VI_VET_SESSION' // checkUpdate
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
